<template>
  <div class="portfolio">
    <Hero 
      heroTitle="Mon métier"
      msg="Qu'est qu'un informaticien de gestion avec un bachelor en Economie d'entreprise fait? Voici un peu de mon travail! 👇"
      image="false"
    />
    <Project
      name="OpenGIS4ET"
      description="Scrum master d'un projet ERA-NET nommé OpenGIS4ET qui vise a faciliter la future transition énergétique en Europe. 
      Cooordination de partenaires de différents pays comme le Dannemark, l'Autriche, l'Allemagne et la Suisse."
      technologies="#Agile #GIS #Docker #Kubernetes #DigitalOcean"
      image='/img/scrum.c918f44c.png'
    />
    
    <Project
      name="joaosilva.ch"
      description="Site vitrine pour la présentation de projets et déploiement de versions tests de multiple projets personnels. 
      Ce site est aussi créé après une envie de faire un projet en vueJS, créer design frontend et faire des recherches UI/UX."
      technologies="#VueJS #CSS #UI/UX"
      image='/img/joaosilva.baf8eecd.png'
      v-bind:contain="true"
    />

    <Project
      name="SCCER-Furies"
      description="Développement pour le project SCCER-Furies d'une application mobile pour la localisation et gestion de bornes de recharge liées à ce projet.
      Les bornes de recharge peuvent aussi être controlées à travers une application web."
      technologies="#Nativescript #Blockchain #SQL #PHP #Testflight"
      image='/img/sccer.f5082ab5.png'
    />

    <Project
      name="pretty nicky mobile app"
      description="Application pour gestion d'agenda, comptabilité et base de données de clients d'un petit commerce à Vevey. Le développement de cette application a été réalisé pour le travail de bachelor"
      technologies="#Dart #Flutter #Firebase #NoSQL"
      image='/img/pretty.87fbdad4.png'
    />
    <Footer
      facebook="https://www.facebook.com/profile.php?id=100000692160799"
      linkedin="https://www.linkedin.com/in/jcfdsilva/"
      instagram="https://www.instagram.com/jcfdsilva_/"
      email="mailto:contact@joaosilva.ch"
    />
    <!-- <img src="../assets/joaosilva.png"/>
    <img src="../assets/sccer.png"/>
    <img src="../assets/scrum.png"/>
    <img src="../assets/pretty.png"/> -->
  </div>
</template>

<script>

// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import Project from '@/components/Project.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Portfolio',
  components: {
    Hero,
    Project,
    Footer
  }
}
</script>

<style scoped>
  
</style>