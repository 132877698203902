<template>
  <div class="portfolio">
    <ContactForm
      attentionGrab="Get in touch."
    />
    <Footer
      facebook="https://www.facebook.com/profile.php?id=100000692160799"
      linkedin="https://www.linkedin.com/in/jcfdsilva/"
      instagram="https://www.instagram.com/jcfdsilva_/"
      email="mailto:contact@joaosilva.ch"
    />
  </div>
</template>

<script>

// @ is an alias to /src
import ContactForm from '@/components/ContactForm.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm,
    Footer
  }
}
</script>

<style scoped>
  
</style>