<template>
  <div class="contact">
      <form>
          <h1>{{attentionGrab}}</h1>
          <input class="form-element" type="text" placeholder="Your Name" name="name" v-model="form.name" v-bind:class="{ error: errors.name }"/>
          <input class="form-element" type="text" placeholder="Your Email" name="email" v-model="form.email" v-bind:class="{ error: errors.email }"/>
          <textarea class="form-element" placeholder="Your Message" name="message" v-model="form.message" v-bind:class="{ error: errors.message }"></textarea>
          <button class="button" type="button" v-on:click="sendMessage">Envoyer message</button>
      </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ContactForm',
  props: {
    attentionGrab:String
  },
  data() {
      return{
          form:{
              name:'',
              email: '',
              message:''
          },
          errors:{
              name:false,
              email: false,
              message:false
          }
      }
  },
  methods: {
      sendMessage(){
          if(this.form.name=="" || this.form.name.length==0){
              this.errors.name=true
          }
          else{
              this.errors.name=false
          }
          if(this.form.email=="" || this.form.email.length==0){
              this.errors.email=true
          }
          else{
            this.errors.email=false
          }
          if(this.form.message=="" || this.form.email.message==0){
              this.errors.message=true
          }
          else{
              this.errors.message=false
          }
          this.validateEmail()
          if(this.errors.name==false && this.errors.email==false && this.errors.message==false ){

            var bodyFormData = new FormData();
            bodyFormData.append('email', this.form.email);
            bodyFormData.append('message', this.form.message);

            axios({
                method: 'post',
                url: 'https://joaosilva.ch/sendemail.php',
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
          }
      },
      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) 
          {
              this.errors.email=false
              }
              else
              {
                  this.errors.email=true
                  }
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact{
    height:calc( 100vh - 107px );
    background-color: rgb(255, 255, 255);
    z-index: 2;
    position: relative;
    padding:0px 20vw;
    text-align: left;
    overflow: hidden;
}
form{
    height:100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}
form *{
    height:45px;
    margin:10px 150px;
}
.form-element{
    border:none;
    border-bottom: 1px solid rgb(121, 121, 121);
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.form-element:focus-visible{
    outline:none;
    border-bottom: 2px solid black;
}
.button{
    width:250px;
    background-color: black;
    color:white;
    margin-top:30px
}
.error{
    border-bottom: 1px solid rgb(189 23 23);
}
@media only screen and (max-width: 750px){
    form * {
        margin:0;
    }
    .button{
        width:100%;
    }
    form{
        margin-top: -50px;
    }
  }
  @media only screen and (max-width: 400px){
    .contact{
        padding:0px 20%;
    }
    h1{
        font-size: 20px;
    }
  }
  @media only screen and (max-width: 1200px){
    .contact{
        padding:0px 40px;
    }
  }
</style>