<template>
  <div class="project" v-bind:class="{ contain: contain }" v-bind:style="{ backgroundImage: 'url('+image+')' }">
      <div  class="projectWrapper">
        <h1>{{name}}</h1>
        <p>{{description}}</p>
        <p>{{technologies}}</p>
      </div>
  </div>


</template>

<script>
require('../assets/joaosilva.png')
require('../assets/sccer.png')
require('../assets/pretty.png')
require('../assets/scrum.png')
export default {
  name: 'AboutMe',
  props: {
    name: String,
    description: String,
    technologies: String,
    image:String,
    contain:Boolean
  },
  computed: {
    cssProps() {
      return {
        '--bg': this.image
      }
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project{
    height:100vh;
    width: 100vw;
    /* background: url('../assets/sccer.png'); */
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 50%;
    z-index: 2;
    position: relative;
}
.projectWrapper{
    width:calc( 50% - 300px );
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 100px 0 200px ;
    text-align: left;
}
h1{
    margin-top:0
}
@media only screen and (max-width: 980px){
  .projectWrapper{
    width:calc( 50% - 100px );
    padding: 0 33px 0 66px ;
  }
  .contain{
    background-color: #c1f390;
    background-size: 130%;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 600px){
  .projectWrapper{
    width:calc( 100% - 99px );
    padding: 0 33px 0 66px ;
  }
}
</style>